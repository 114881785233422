import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import picOffice01 from '../assets/images/office-01.jpg'

const EmployeeBenefits = (props) => (
    <Layout>
        <Helmet>
            <title>Generic - Forty by HTML5 UP</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Case Study: Restaurants</h1>
                    </header>
                    <span className="image main"><img src={picOffice01} alt="" /></span>
                    
                    <h4>Need More Specifics?</h4>
                    <p>
                        As always, you can <Link to="/how-it-works">click here</Link> to learn more about the foundtion of our Parlour Party Box service and what to expect.
                    </p>
                    <h2>Additional Reading</h2>
                    <ul>
                        <li><a href="https://www.foodnewsfeed.com/fsr/new-concepts/how-board-game-restaurants-are-winning-big-customers" target="_blank">How Board Game Restaurants Are Winning Big - Food News Feed</a></li>
                    </ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default EmployeeBenefits